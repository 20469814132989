@import "styles/mixins.scss";
@import "styles/variables.scss";

.day-list {
  &__item {
    @include fade;

    color: #8395a7;
    height: 5rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      background-color: #ee5253;
      cursor: pointer;
      color: $dark-background;
    }

    &--selected {
      background-color: darken($white, 5%);
      color: $dark-background;

      &:hover {
        background-color: darken($white, 5%);
      }
    }

    &--full {
      opacity: $inactive-opacity;
    }
  }
}
