@import "styles/variables.scss";

.interviewers {
  &__header {
    color: $white;
    margin-bottom: 0.5rem;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
  }
}
