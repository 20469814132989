$font-family: "Source Sans Pro", sans-serif;

$active-opacity: 1;
$inactive-opacity: 0.15;

$white: #fff;
$black: #000;

$dark-background: #222f3e;

$weeks-colors: #54a0ff, #48dbfb, #1dd1a1, #5f27cd;
$booked-color: #54a0ff;
$danger-color: #ee5253;
$error-color: #ff9f43;
$confirm-color: #10ac84;

$spacing: 2rem;
