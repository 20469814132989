@import "styles/mixins.scss";
@import "styles/variables.scss";

.button {
  border-radius: 1rem;
  border: 0;
  font-size: 1.5rem;
  height: 2rem;
  line-height: 1.2rem;
  margin-right: 1rem;
  width: 8rem;
  border-width: 2px;
  border-style: solid;
  border-color: $white;
  color: $white;
  background-color: transparent;
}

.button:hover {
  @include button-active;

  color: $black;
  background-color: $white;
  border: 2px solid transparent;
}

.button--danger {
  color: $danger-color;
  border-color: $danger-color;
  background-color: transparent;
}

.button--confirm {
  color: $confirm-color;
  border-color: $confirm-color;
  background-color: transparent;
}

.button--danger:hover {
  color: $white;
  background-color: $danger-color;
}

.button--confirm:hover {
  color: $white;
  background-color: $confirm-color;
}

.button:last-of-type {
  margin-right: 0;
}

.button:focus {
  outline: none;
}

.button:disabled {
  border-style: solid;
  border-color: $white;
  color: $white;
  background-color: transparent;
  opacity: 0.7;
}
